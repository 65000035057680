<script lang="js">
  import Logo from "./assets/logodednotext.svg";
</script>

<div class="footer">
  <div style="display: flex; flex-direction: row; justify-content: space-evenly; margin-top: 20px;">
    <div class="footer-column" style="text-align: left;">
      <div style='display: flex; width: 100%; border: 1px solid white;'>
        <img src={Logo} width="80" alt="LogoDed"/>
        <h1 style="margin-top: auto; margin-bottom: auto; margin-left: 20px;">D&D Partners</h1>
      </div>
      <div style="min-height: 20px;"/>
      <div style="font-size: 20px;">Via Guido Rossa 8<br/>
        35020 Ponte San Nicolò (PD)<br/>
        Mail: info@dedpartners.com<br/>
        Tel: +39 3802104571</div>
    </div>
    <div class="footer-column">
      <h1>MENU</h1>
      <div style="min-height: 20px;"/>
      <div style="font-size: 20px; display: flex; flex-direction: column;">
        <a href="/">Home</a>
        <a href="/product">Il prodotto</a>
        <a href="/services">Servizi</a>
        <a href="/contacts">Contatti</a>
      </div>
    </div>
    <div class="footer-column">
      <h1>SOCIALS</h1>
      <div style="min-height: 20px;"/>
      <div style="font-size: 35px;">
        <a href="https://www.linkedin.com/company/dedpartners/?viewAsMember=true">
          <i class="fa-brands fa-linkedin"></i>
        </a>
      </div>
    </div>
  </div>
  
  <div style="min-height: 50px;"/>
  <div style="display: flex; flex-direction: row; margin: 0px 50px;">
    <a href="https://www.dedpartners.com/privacypolicy">Informativa sul trattamento dei dati</a> <!--TODO: Fixare con il link giusto-->
    <div style="flex-grow: 1;"/>
    <div>©2024 D&D Partners S.R.L.</div>
  </div>
  <div style="min-height: 50px;"/>
</div>

<style>
    .footer {      
      padding-top: 50px;
      color: white;
      background-color: var(--main-color);
      width: 100%;
    }
    
    .footer a {
      color: white;
    }

    .footer-column {
      width: 300px;
      text-align: center;
      display:flex;
      flex-direction: column;
    }
    
    .footer-column a {
      padding: 10px;
    }

</style>
