<div style="overflow: hidden;">
  <div class="sfondo">
    <div
      style="position: absolute; width: 100%; min-height: 300px; top: 10vh; text-align: center;"
    >
      <div style="width: 100%; text-align: left; color: white;">
        <div class="big-header">gravehub.it</div>
      </div>
    </div>
    <!-- <div style="position: absolute; right: 0; bottom: 200px; width: 500px; max-height: 300px; text-align: center; display: flex; flex-direction: column;"> -->
    <!--   <div style="margin-right: 0px; padding: 30px;"> -->
    <!--     <h2 style="font-weight: 300; font-size: 40px;"> -->
    <!--       LA GESTIONE DEL TUO CIMITERO <br/> NON È MAI STATA COSÌ SEMPLICE -->
    <!--     </h2> -->
    <!--     <div style="min-height: 15px;"/>  -->
    <!--     <h1 style="font-size: 60px;">COME FUNZIONA?</h1> -->
    <!--     <div style="min-height: 15px;"/>  -->
    <!--     <button style="border: 1px solid black; background-color: unset; padding: 15px; font-size: 25px;"> CONTATTACI </button> -->
    <!--   </div> -->
    <!-- </div> -->
    <video class="parallax-video" width="100%" autoplay loop muted>
      <source src="./prodotto.mp4" type="video/mp4" />
      <track default kind="captions" srclang="en" src="SUBTITLE_PATH" />
      Il tuo browser non supporta il tag video.
    </video>
  </div>
  <div style="min-height: 100px" />
  <div style="display: flex; flex-direction: column;">
    <div
      style="font-size: 25px; text-align: center; line-height: 35px; padding: 0px 100px;"
    >
      GraveHub® è un software made in Italy, pensato e realizzato dalla società
      D&D Partners S.r.l.<br /><br />
      Il prodotto vuole aiutare tutte le amministrazioni e non, nella gestione del/dei
      cimitero/i.<br /><br />
      Grazie a questo software gli amministratori non dovranno più preoccuparsi quando
      e come gestire le logistiche dei defunti, stato dei pagamenti rispetto ai singoli
      defunti o agli aventi diritto, e/o addirittura rispetto alla manutenzione del
      cimitero stesso. <br /><br />
      L'obiettivo è rendere efficiente l'intero processo di gestione e prenotazione
      dei loculi all'interno del Comune. <br /><br />
      Il software è perfettamente integrabile e interoperabile con tutti i software
      attualmente in uso dagli amministratori.
    </div>
    <!-- <img src="https://static.wixstatic.com/media/11062b_c4078dd1d6354f6eb10944ec4d1b064a~mv2_d_6000_4000_s_4_2.jpg/v1/fill/w_760,h_523,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_c4078dd1d6354f6eb10944ec4d1b064a~mv2_d_6000_4000_s_4_2.jpg" alt="productimage" /> -->
    <!-- </div>   -->
  </div>
  <div style="min-height: 50px" />
</div>

<style>
  .sfondo {
    width: 100%;
    height: 93vh;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  .big-header {
    font-family: Blue Highway;
    font-size: 100px;
    padding: 0px 50px;
    letter-spacing: 20px;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
</style>
