<script lang="js">
  export let isActive;
</script>

<div class="nav-button {isActive? 'active':''}">
  <slot />
</div>

<style>
  .nav-button {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 5px 10px;
    border-left: 1px solid var(--main-color);
    /* border-right: 1px solid var(--main-color); */
    min-width: 130px;
    text-decoration: none;
    transition-duration: .3s;
  }

  .active {
    background-color: var(--main-color);
    color: white;
  }

  .nav-button:hover{
    background-color: var(--main-color);
    color: white;
  }

</style>

