<script lang="js">
  import { Router, Link, Route } from "svelte-routing";
  import Home from "./routes/Home.svelte";
  import Product from "./routes/Product.svelte";
  import Services from "./routes/Services.svelte";
  import Contacts from "./routes/Contacts.svelte";
  import Footer from "./Footer.svelte";
  import NavButton from "./lib/components/NavButton.svelte";
  import Logo from "./assets/logo_gravehub.svg";

  export let url = "";
</script>

<Router {url}>
  <nav class="main-nav">
    <Link
      to="/"
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto; font-size: 40px;"
    >
      <img src={Logo} alt="logo" width="70px" />
    </Link>
    <div style="flex-grow: 2" />
    <Link
      to="/"
      let:active
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto;"
      ><NavButton isActive={active}>HOME</NavButton></Link
    >
    <Link
      to="/product"
      let:active
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto;"
      ><NavButton isActive={active}>PRODOTTO</NavButton></Link
    >
    <Link
      to="/services"
      let:active
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto;"
      ><NavButton isActive={active}>SERVIZI</NavButton></Link
    >
    <Link
      to="/contacts"
      let:active
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto;"
      ><NavButton isActive={active}>CONTATTI</NavButton></Link
    >
    <a
      href="https://demo.gravehub.it/"
      style="text-decoration: unset; margin-top: auto; margin-bottom: auto;"
    >
      <NavButton isActive={false}>RICHIEDI LA DEMO</NavButton>
    </a>
  </nav>

  <div>
    <Route path="/"><Home /></Route>
    <Route path="/product"><Product /></Route>
    <Route path="/services"><Services /></Route>
    <Route path="/contacts"><Contacts /></Route>
    <Footer />
  </div>
</Router>

<style>
  .main-nav {
    height: 70px;
    padding: 0px 120px 0px 120px;
    display: flex;
    flex-direction: row;
    background-color: white;
    top: 0;
    position: sticky;
    z-index: 2;
  }
</style>
