<script lang="js">
  import Logo from "../assets/logo_gravehub.svg";
  // import video_home from "../assets/home.mp4";
</script>

<div class="home-page">
  <div style="position: absolute; width: 100%; min-height: 300px; top: 20vh; text-align: center;">
    <img src={Logo} alt="logo" width="500" height="300"/>
  </div>
  <div class="sfondo">
    <video class="parallax-video" width="100%" autoplay loop muted>
      <source src="./home.mp4" type="video/mp4">
      <track default kind="captions" srclang="en" src="SUBTITLE_PATH" />
      Il tuo browser non supporta il tag video.
    </video>
    <div style="position: relative; width: 100%; max-height: 300px; margin-top: -300px; text-align: center; display: flex;">
      <div style="background-color: rgba(255,255,255, .5); margin-left: auto; margin-right: auto; max-width: 850px; flex-grow: 1; padding: 30px;">
        <h2 style="font-weight: 300; font-size: 40px;">
          LA GESTIONE DEL TUO CIMITERO <br/> NON È MAI STATA COSÌ SEMPLICE
        </h2>
        <div style="min-height: 15px;"/> 
        <h1 style="font-size: 60px;">COME FUNZIONA?</h1>
        <div style="min-height: 15px;"/> 
        <button style="border: 1px solid black; background-color: unset; padding: 15px; font-size: 25px;"> CONTATTACI </button>
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row; padding: 100px; justify-content: space-evenly;">
    <div class="card-home">
      <i class="fa-solid fa-map-location"></i>
      <h1>LOGISTICO</h1>
      <div style="min-height: 20px;"/>
      <div>  
        Puoi costruire il tuo nuovo "Piano regolatore cimiteriale". Avrai sotto controllo tutta la situazione logistica dei tuoi defunti. Non dovrai più perdere tempo per cercare il defunto tra le mille carte, basterà fare una ricerca tramite l'apposita sezione e ti verrà restituita la scheda defunto.
      </div>
    </div>
    <div class="card-home">
      <i class="fa-solid fa-book"></i>
      <h1>AMMINISTRATIVO</h1>
      <div style="min-height: 20px;"/>
      <div>Potrai tenere sotto controllo la situazione estumulazioni, pagamenti e lampade votive. La Piattaforma ti invierà email e notifiche prima di ogni scadenza! Grazie a questo modulo, saprai anche con quale erede dovrai metterti in contatto. Sarà possibile, inoltre, caricare tutti i contratti afferenti al defunto.
      </div>
    </div>
    <div class="card-home">
      <i class="fa-solid fa-toolbox"></i>
      <h1>MANUTENZIONE</h1>
      <div style="min-height: 20px;"/>
      <div> 
        Non preoccuparti più di ricordarti quale tipo di manutenzione è stata effettuata, quando e da chi! Grazie a questo modulo potrai tenere traccia delle manutenzioni che sono state effettuate, e programmare le nuove manutenzioni. 
      </div>
    </div>
  </div>

  <div>
    
  </div>

</div>

<style>
  .sfondo {
    width: 100%;
    height: 93vh;
    border: 1px solid black;
  }

  .parallax-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>




<!-- <div class="primo-piano" on:scroll={scrollModifier}> -->
   <!--  <\!-- <div style="height: 100vh;" /> -\-> -->
    
   <!--    <div class="how-it-works"> -->
   <!--      <div class="wrapper"> -->
   <!--        <div class="layer"> -->
   <!--          <h2 class=how-it-works-title>Cosa facciamo</h2> -->
   <!--        </div> -->
   <!--      <div class="layer"> -->
   <!--        <div class="card">a</div> -->
   <!--        <div class="card">a</div> -->
   <!--        <div class="card">a</div> -->
   <!--      </div> -->

   <!--      <\!-- <div class="layer"> -->
   <!--        <div class="card" style="bottom:{offsetY>300? '80':'0'}px; right: {offsetY>300? '260' : '0'}px" bind:this={primaCard} > -->
   <!--          <div class="card-img"> -->
   <!--          </div> -->
   <!--          <div class="card-content"> -->
   <!--            <p>Costruiamo il tuo cimitero dal punto di vista <span class="text-big">logistico</span>. -->
   <!--              Avrai sotto controllo tutta la situazione logistica dei tuoi defunti. -->
   <!--              Non dovrai più perdere tempo per cercare il defunto tra le mille carte, basterà fare una ricerca tramite l'apposita sezione e ti verrà restituita la scheda defunto.</p> -->
   <!--            <p>Vedi di più</p> -->
   <!--          </div> -->
   <!--        </div> -->
   <!--      </div> -\-> -->

   <!--      <\!-- <div class="layer"> -->
   <!--       <div class="coffin" style="transform: rotate({rotation}deg) scale({offsetY >= 1200 ? 0.5 : 1}); top: {coffinOffsetY}px;"> -->
   <!--       </div> -->
         
   <!--        <div class="card" style="right: {offsetY>500? '80' : '0'}px"> -->
   <!--          <div class="card-img" /> -->
   <!--          <div class="card-content"> -->
   <!--            <p>L'<span class="text-big">Amministrazione</span> non sarà più un pensiero: Potrai tenere sotto controllo la situazione estumulazioni, pagamenti e lampade votive.  -->
   <!--              La Piattaforma ti invierà email e notifiche prima di ogni scadenza! -->
   <!--              Grazie a questo modulo, saprai anche con quale erede dovrai metterti in contatto.  -->
   <!--              Sarà possibile, inoltre, caricare tutti i contratti afferenti al defunto.</p> -->
   <!--            <p>Vedi di più</p> -->
   <!--          </div> -->
   <!--        </div> -->
   <!--      </div> -\-> -->
 
   <!--      <\!-- <div class="layer"> -->
   <!--        <div class="card" style="right: {offsetY>700? '260' : '0'}px"> -->
   <!--          <div class="card-img" /> -->
   <!--          <div class="card-content"> -->
   <!--            <p> -->
   <!--             Non preoccuparti più di ricordarti quale tipo di <span class="text-big">Manutenzione</span> è stata effettuata, quando e da chi!  -->
   <!--              Grazie a questo modulo potrai tenere traccia delle manutenzioni che sono state effettuate, e programmare le nuove manutenzioni. -->
   <!--           </p> -->
   <!--           </div> -->
   <!--        </div> -->
   <!--      </div> -\-> -->
   <!--    </div> -->
   <!--  </div> -->
     
   <!--   <\!-- <div class="call-to-action"> -\-> -->
   <!--   <\!--   <button>Richiedi la demo</button> -\-> -->
   <!--   <\!-- </div> -\-> -->
   <!-- </div> -->   



<!-- .primo-piano { -->
<!--     position: absolute; -->
<!--     width: 100%; -->
<!--     height: 100vh; -->
<!--     overflow: scroll; -->
<!--     scrollbar-width: none; -->
<!--     z-index: 1; -->
<!--   } -->

<!--   .wrapper { -->
<!--     width: 80%; -->
<!--     margin: auto; -->
<!--   } -->

<!--   .how-it-works { -->
<!--     background-color:rgba(3, 40, 100, 0.7); -->
<!--     display: flex; -->
<!--     flex-direction: column; -->
<!--     justify-content: space-around; -->
<!--     padding: 50px; -->
<!--     width: 100%; -->
<!--     margin: auto; -->
<!--   } -->

<!--   .how-it-works-title { -->
<!--     color: white; -->
<!--   } -->

<!--   .text-big { -->
<!--     font-size: 46px; -->
<!--   } -->

<!--   .layer { -->
<!--     display: flex; -->
<!--     flex-direction: row; -->
<!--     justify-content: space-between; -->
<!--     margin: -10px; -->
<!--   } -->

<!--   .card { -->
<!--     flex: 1 1 auto; -->
<!--     background-color: white; -->
<!--     justify-content: space-around; -->
<!--     margin: 10px; -->
<!--     padding: 20px; -->
<!--     border-radius: 15px; -->
<!--   } -->

<!--   /* .card { -->
<!--     display: flex; -->
<!--     height: 300px; -->
<!--     margin: 40px 4px 40px auto; -->
<!--     padding: 25px; -->
<!--     border-radius: 25px; -->
<!--     box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); -->
<!--     background-color: whitesmoke; -->
<!--     overflow: hidden; -->
<!--     position: relative; -->
<!--     transition: right ease 3s, bottom ease 3s; -->
<!--     transition-duration: 1s; -->
<!--   } */ -->

<!--   .card-img { -->
<!--     width: 45%; -->
<!--     border: 1px red solid; -->
<!--     background-color: white; -->
<!--     display: flex; -->
<!--     flex-shrink: 0; -->
<!--     height: 150px; -->
<!--     border-radius: 25px; -->
<!--     margin: auto 5px; -->
<!--     box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2); -->
<!--   } -->

<!--   .coffin { -->
<!--     width: 300px; -->
<!--     height: 400px; -->
<!--     background-image: url('../assets/grave_logo.svg'); -->
<!--     background-size: cover; -->
<!--     background-position: center; -->
<!--     transition: all ease 3s; -->
<!--     position: relative; -->
<!--   } -->

<!--   .call-to-action { -->
<!--     height: 100px; -->
<!--     background-color: peru; -->
<!--     display: flex; -->
<!--     align-items: center; -->
<!--     justify-content: center; -->
<!--   } -->

<!--   /* .moving-box { -->
<!--     position: fixed; -->
<!--     width: 150px; -->
<!--    height: 200px; -->
<!--    background-color: red; -->
<!--    transition-duration: 1s; -->
<!--   } */ -->
