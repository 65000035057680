<div style="width: 100%;">
  <div
    style="height: 400px; border: 1px solid black; color: white; font-size: 80px; text-align: center; background-image: url('https://static.wixstatic.com/media/nsplsh_624c6aecdb4a47d18b16ccd01d295c08~mv2.jpg/v1/fill/w_1805,h_419,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Dmitry%20Dmitry.jpg'); padding: 50px;"
  >
    INFORMAZIONI <br /> DI <br /> PROGETTO
  </div>

  <div
    style="display: flex; flex-direction: row; padding: 20px 100px; justify-content: space-evenly;"
  >
    <div class="card-home">
      <i class="fa-solid fa-envelope"></i>
      <h2>Contattaci</h2>
      <div style="min-height: 20px;" />
      <div>Scrivi una email all'indirizzo:</div>
      <a href="#email-info" style="font-size: 30px;">info@dedpartners.com</a>
      <!--TODO: METTERE EMAIL COMPILAZIONE-->
    </div>
    <div class="card-home">
      <i class="fa-solid fa-desktop"></i>
      <h2>Richiedi la demo gratuita</h2>
      <div style="min-height: 20px;" />
      <div>
        <ul>
          <li>La demo presenterà funzionalità d'esempio;</li>
          <li>Sarà presente un cimitero preconfigurato</li>
          <li>Il suo utilizzo darà limitato a 5 giorni</li>
        </ul>
      </div>
    </div>
    <div class="card-home">
      <i class="fa-solid fa-circle-question"></i>
      <h2>Cosa posso fare nella demo?</h2>
      <div style="min-height: 20px;" />
      <div>
        Gestire gli aspetti:
        <ul>
          <li>Amministrativi</li>
          <li>Logistici</li>
          <li>Contabili</li>
        </ul>
      </div>
    </div>
  </div>

  <div
    style="display: flex; flex-direction: row; padding: 20px 100px; justify-content: space-evenly;"
  >
    <div class="card-home">
      <i class="fa-solid fa-layer-group"></i>
      <h2>Cosa posso fare con GraveHub?</h2>
      <div style="min-height: 20px;" />
      <div>
        <ul>
          <li><b>Consultare</b> la dashboard</li>
          <li>
            <b>Ricercare</b> all'interno del mio database filtrando secondo una serie
            di dati
          </li>
          <li>
            <b>Censire</b> nuovi defunti e cimiteri, modificando eventualmente anche
            i dati già censiti
          </li>
          <li><b>Esplorare</b> tutti i dati presenti nel database</li>
          <li><b>Configurare</b> le notifiche</li>
        </ul>
      </div>
    </div>
    <div class="card-home">
      <i class="fa-solid fa-gear"></i>
      <h2>Configurazione in solitaria del mio cimitero</h2>
      <div style="min-height: 20px;" />
      <div>
        <ul>
          <li>Posso caricare la planimetria del mio cimitero</li>
          <li>
            Posso dividere il mio cimitero in settori con un sistema multi layer
          </li>
          <li>
            Posso caricare all'interno di ogni settore, la tipologia e le
            risorse che contiene (es. tombe, ossari, etc..)
          </li>
        </ul>
      </div>
    </div>
    <div class="card-home">
      <i class="fa-solid fa-ticket-simple"></i>
      <h2>Sistema di ticketing</h2>
      <div style="min-height: 20px;" />
      <div>
        Per qualsiasi problema, è possibile aprire un ticket direttamente dalla
        Piattaforma. Si riceveranno risposte entro - e non oltre- le 72h.
      </div>
    </div>
  </div>
</div>

<!-- <section class="how-we-do-it"> -->
<!--     <h1>Come lo Facciamo?</h1> -->
<!--     <p>Scopri i nostri metodi e processi.</p> -->

<!-- <div class="turnkey"> -->
<!--     <h2>Servizio chiavi in mano</h2> -->

<!--     <div class="process"> -->
<!--         <h2>1. Pianificazione</h2> -->
<!--         <p>Iniziamo con una fase di pianificazione dettagliata per capire le esigenze del cliente.</p> -->
<!--     </div> -->

<!--     <div class="process"> -->
<!--         <h2>2. Sviluppo</h2> -->
<!--         <p>Passiamo alla fase di sviluppo, dove realizziamo il progetto secondo le specifiche concordate.</p> -->
<!--     </div> -->

<!--     <div class="process"> -->
<!--         <h2>3. Testing</h2> -->
<!--         <p>Ogni progetto viene testato accuratamente per garantire che soddisfi gli standard di qualità.</p> -->
<!--     </div> -->

<!--     <div class="process"> -->
<!--         <h2>4. Lancio</h2> -->
<!--         <p>Infine, il progetto viene lanciato e monitorato per assicurare il suo successo.</p> -->
<!--     </div> -->
<!-- </div> -->

<!-- <div class="diy"> -->
<!--     <h2>Fai da te</h2> -->
<!-- </div> -->

<!-- </section> -->

<!-- <style> -->
<!--     .turnkey { -->
<!--         background-color: rgb(127, 255, 148); -->
<!--         height: 300px; -->
<!--     } -->

<!--     .diy { -->
<!--         text-align: end; -->
<!--     } -->
<!-- </style> -->

<style>
</style>
