<script lang="js">
  // import { nodemailer } from "../../node_modules/nodemailer/";

  // interface formData {
  //   nome      : string;
  //   cognome   : string;
  //   email     : string;
  //   contenuto : string;
  //   data      : Date;
  // }

  // async function compileEmail(event: Event) {
  //   let t = event.target as HTMLFormElement;
  //   let fd = new FormData(t);
  //   const dataToSend : formData = {
  //     nome      : fd.get("nome") as string,
  //     cognome   : fd.get("cognome") as string,
  //     email     : fd.get("email") as string,
  //     contenuto : fd.get("testomex") as string,
  //     data      : new Date(),
  //   };
  // }

  // const transporter = nodemailer.createTransport({
  //   host: "smtp.ethereal.email",
  //   port: 587,
  //   secure: false, // true for port 465, false for other ports
  //   auth: {
  //     user: "maddison53@ethereal.email",
  //     pass: "jn7jnAPss4f63QBp6D",
  //   },
  // });

  // async function sendStuff() {
  //   const info = await transporter.sendMail({
  //     from: '"" <maddison53@ethereal.email>', // sender address
  //     to: "cristiano@dedpartners.com, info@dedpartners.com", // list of receivers
  //     subject: "Hello ✔", // Subject line
  //     text: "Hello world?", // plain text body
  //     html: "<b>Hello world?</b>", // html body
  //   });
  //   console.log("Message sent: %s", info.messageId);
  // }


</script>

<div>
  <div style="background-image: url(https://static.wixstatic.com/media/11062b_487580c1c949412eb89b1c938213d6a0f000.jpg/v1/fill/w_1901,h_348,al_c,q_85,usm_0.33_1.00_0.00,enc_auto/11062b_487580c1c949412eb89b1c938213d6a0f000.jpg); height: 350px; text-align: center; display: flex; flex-direction: column;">
    <div style="flex-grow: 1"/>
    <div style="margin-top: auto; margin-bottom: auto; font-size: 50px;">Contatti</div>
    <div style="flex-grow: 1"/>
  </div>

  <div style="display: flex; flex-direction: row; padding: 20px 100px; justify-content: space-evenly;">
    <div class="card-contatti">
      <i class="fa-solid fa-location-dot"></i>
      <h2>Indirizzo</h2>
      <div style="min-height: 20px;"/>
      <div>
        Via Guido Rossa 8 <br/> 35020 - Ponte San Nicolò (PD)
      </div>
    </div>
    <div class="card-contatti">
      <i class="fa-solid fa-phone"></i>
      <h2>Telefono</h2>
      <div style="min-height: 20px;"/>
      <div> 
        +39 049 0962657 <br/> +39 380 2104571
      </div>
    </div>
  </div>
  <div style="display: flex; flex-direction: row; padding: 20px 100px; justify-content: space-evenly;">
    <div class="card-contatti">
      <i class="fa-solid fa-envelope"></i>
      <h2>Email</h2>
      <div style="min-height: 20px;"/>
      <div>
        info@dedpartners.com
      </div>
    </div>
    <div class="card-contatti">
      <!-- <i class="fa-solid fa-thumbs-up"></i> -->
      <div style="min-height: 20px;"/>
      <a href="https://www.linkedin.com/company/dedpartners/?viewAsMember=true">
        <i class="fa-brands fa-linkedin" style="font-size: 30px;"></i>
      </a>
      <h2>Social Media</h2>
    </div>
  </div>

  <!-- <div style="width: 100%; display: flex; flex-direction: row; border: 1px solid var(--main-color);"> -->
  <!--   <div style="width: 30%; height: 500px; background-color: var(--main-color); display: flex; flex-direction: column; text-align: center;"> -->
  <!--     <div style="flex-grow: 1;"/> -->
  <!--     <div style="color: white; font-size: 40px; font-weight: 300;">Richiedi la demo</div> -->
  <!--     <div style="flex-grow: 1;"/> -->
  <!--   </div> -->
  <!--   <\!-- <div style="width: 70%; padding: 0px 250px; margin-top: auto; margin-bottom: auto;"> -\-> -->
  <!--     <\!-- <form> -\-> -->
  <!--     <\!--   <div style="display: flex;"> -\-> -->
  <!--     <\!--     <div class="overlapped-input-label"> -\-> -->
  <!--     <\!--       <label for="nome">Nome</label> -\-> -->
  <!--     <\!--       <input name="nome" type="text"/> -\-> -->
  <!--     <\!--     </div> -\-> -->
  <!--     <\!--     <div style="min-width: 20px;"/> -\-> -->
  <!--     <\!--     <div class="overlapped-input-label"> -\-> -->
  <!--     <\!--       <label for="cognome">Cognome</label> -\-> -->
  <!--     <\!--       <input name="cognome" type="text"/> -\-> -->
  <!--     <\!--     </div> -\-> -->
  <!--     <\!--   </div> -\-> -->
  <!--     <\!--   <div class="overlapped-input-label"> -\-> -->
  <!--     <\!--     <label for="email">Email *</label> -\-> -->
  <!--     <\!--     <input name="email" type="email" required/> -\-> -->
  <!--     <\!--   </div> -\-> -->
  <!--     <\!--   <div class="overlapped-input-label"> -\-> -->
  <!--     <\!--     <label for="testomex">Testo del messaggio</label> -\-> -->
  <!--     <\!--     <textarea rows="5"></textarea> -\-> -->
  <!--     <\!--   </div> -\-> -->
  <!--     <\!--   <div> -\-> -->
  <!--     <\!--     <input type="submit" value="Invia" style="background-color: var(--main-color); width: 100%; height: 40px; color: white; border: none; font-size: 18px;" /> -\-> -->
  <!--     <\!--     Grazie per la richiesta! -\-> -->
  <!--     <\!--   </div> -\-> -->
  <!--     <\!-- </form> -\-> -->
  <!--   <\!-- </div> -\-> -->
  <!-- </div> -->
</div>

<style>
  .card-contatti {
    text-align: center;
    width: 400px;
  }

  .card-contatti div {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .card-contatti i {
    font-size: 30px;
    padding: 20px;
  }
  
  /* .overlapped-input-label { */
  /*   flex-grow: 1; */
  /*   display: flex; */
  /*   flex-direction: column; */
  /*   margin-bottom: 25px; */
  /* } */

  /* .overlapped-input-label input { */
  /*   border-radius: 0px; */
  /*   border: 1px solid var(--main-color); */
  /*   font-size: 30px; */
  /* } */

</style>
